.App {
  text-align: center;
}

.App-header {
  background-color: #859C70;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
  
}

button {
  display: flex;
  border: none;
  background-color: #E7D8DD;
  border-radius: 20px;
  justify-content: center;
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

button:hover {
  background-color: grey;
  transform: scale(1.03);
}

button:focus {
  background-color: black;
  color: white;
}

.Heading {
  display: flex;
  border-radius: 20px;
  background-color: #E7D8DD;
  height: 8vh;
  margin-top: 2vh;
  width: 90%;
  margin-bottom: 2vh;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px); /* Backdrop filter for a frosted glass effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.Heading h1 {
  font-size:  20px;
  font-family: Georgia;
  font-weight: 600;
}

.imageBlockSVGS {
  display: grid;
  gap: 16px; 
  width: 90%;
  border-radius: 20px;
}

@media (min-width: 768px) { /* Adjust the breakpoint as per your design */
  .imageBlockSVGS {
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-template-rows: repeat(2, auto); /* 2 rows */
  }
}

/* Mobile view: 1x6 configuration */
@media (max-width: 767px) { /* Adjust the breakpoint as per your design */
  .imageBlockSVGS {
    grid-template-columns: 1fr; /* 1 column */
    grid-template-rows: repeat(6, auto); /* 6 rows */
    margin-bottom: 10px;
    max-height: 86vh;
    overflow-y: auto;
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    /* Create a custom scrollbar for webkit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
    display: none;
    }
  }
}

.svgImage {
  width: 200px;
  height: 300px;
  margin-bottom: -50px;
}

.countryButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Georgia;
  font-size: 20px;
  position: relative;
  backdrop-filter: blur(10px); /* Backdrop filter for a frosted glass effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.countryButton :hover {
}

.newContentTag {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  margin: 10px;
  padding: 10px 15px;
}

.newContentTag img {
  width: 20px;
  height: 20px;
  margin-right: 5px;  
}
.newContentTag p {
  margin: auto;
  color: black;
  font-size: 14px;
}

.oldTag {
  background-color: rgba(43, 38, 38, 0.3);
  backdrop-filter: blur(10px); /* Backdrop filter for a frosted glass effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.currentTag {
  background-color: #ac170fa9;
  backdrop-filter: blur(10px); /* Backdrop filter for a frosted glass effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}