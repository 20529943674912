.countryPage {
  background-color: #A1B8F5;
}

.entries {
    background-color: #6472BA;
    width: 20vw;
    min-width: 120px;
    display: flex;
    flex-direction: column;    
    margin-top: 2vw;
    margin-left: 2vw;
    border-radius: 20px;
    margin-bottom: 3vh;
  }

  .listArea {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    height: 100%;
    padding: 0 10px;
  }

  .entryDetails {
    width: 80vw;
    padding-top: 2vw;
    padding-left: 2vw;
    padding-right: 2vw;
    text-align: left;
    margin-bottom: 3vh;
  }

  .Entrybutton {
    display: flex;
    border: none;
    transition-duration: 0.2s;
    width: 100%;
    font-family: georgia;
    font-weight: 600;
    padding: 10px 0;
    margin: 0;
    background-color: #242B5B;
    color: #CCD9F5;
  }

  .Entrybutton:hover {
    background-color: grey; /* Green */
    color: white;
  }

  .Entrybutton:focus {
    background-color: black;
    color: white;
  }

  .countryHeader { 
    height: 8vh;
    display: flex;
    flex-direction: row;
    background-color: #6472BA;
    color: black;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-left: 2vw;
    margin-right: 2vw;
  }

  .countryBulk {
    height: 92vh;
    display: flex;
    flex-direction: row;
  }

  .returnButtonArea {
    width: 20vw;
    display: flex;
    align-items: center;
    margin-left: 3vw;
  }

  .returnButton {
    display: flex;
    align-items: center;
    width: 30px;
    max-width: 30px;
    height: 4vh;
    background-color: #18206F; /* Green */
    color: white;
    transition-duration: 0.2s;
    font-size: 15px;
    text-align: center;
    font-family: Georgia;
  }

  .returnButton:hover {
    background-color: grey;
    color: white;
  }

  .Title {
    position: absolute;
    pointer-events: none;
    margin: auto;
    text-align: center;
    width: 96vw;
    padding-top: 2.5vh;
    font-size: 18px;
    font-family: Georgia;
    font-weight: 600;
  }

  img {
    display: block;
    width: auto;
    height: auto;
    /*border-radius: 20px;*/
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .imageBlock {
    display: grid;
    gap: 16px;
    row-gap: 2vw;
    width: 100%;
    overflow-y: auto;
    max-height: 87vh;
    border-radius: 20px;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    /* Create a custom scrollbar for webkit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
    display: none;
    }
  }

  /* desktop view: 4x6 configuration */
  @media (min-width: 1200px) {
    .imageBlock {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(6, auto);
    }
    img {
      max-width:100%;
      max-height:100%;
    }
    .writingCard {
      max-width: 100%;
    }
    .startPage {
      justify-content: center;
      align-items: center;
    }
    .startPage p {
      font-size: 25px;
      max-width: 40vw;
    }
    .Entrybutton {
      font-size: 20px;
    }
    .date {
      font-size: 15px;
    }
  }
  
  /* Mobile view: 1x6 configuration */
  @media (max-width: 1199px) { /* Adjust the breakpoint as per your design */
    .imageBlock {
      grid-template-columns: 1fr; /* 1 column */
      grid-template-rows: repeat(6, auto); /* 6 rows */
    }
    img {
      max-width:100%;
      max-height:100vw;
    }
    .writingCard {
      max-width: 100%;
    }
    .startPage {
      justify-content: flex-start;
    }
    .startPage p {
      font-size: 18px;
    }
    .Entrybutton {
      font-size: 13px;
    }
    .date {
      font-size: 12px;
    }
  }

  .writingCard {
    border-radius: 20px;
    background-color: #242B5B;
    padding: 0px 20px;
    padding-bottom: 10px;
    min-height: 270px;
    font-family: Georgia;
    color: #CCD9F5;
  }

  .imgText {
    margin: 15px;
    font-family: Georgia;
  }

  .imgBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #7187F3;
    border-radius: 20px;
    color: #CCD9F5;

  }

  .startPage {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background-color: #242B5B;
    padding: 0px 20px;
    padding-bottom: 10px;
    height: stretch;
    font-family: Georgia;
    color: #CCD9F5;
  }

  .startPage p {
    font-family: georgia;
    font-weight: 600;
  }

  
  .date {
    text-align: right;
    margin: 0;
    margin-bottom: 10px;
    margin-right: 10px;
  } 

  .closeButton {
    background-color: black;
    color: #CCD9F5;
    padding: 10px;
  }